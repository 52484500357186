import React, {useState, useEffect } from "react";
import DataAdapter from "./adapter/DataAdapter";
import Page from 'components/Page';
import TaskDetail from './model/TaskDetails/TaskDetail';
import {Label, Input, Modal, ModalBody, ModalFooter, ModalHeader, Card, CardBody, CardHeader,
  CardSubtitle,
  CardText,
 Col, Row, Table, Button, ButtonGroup, Form, FormGroup} from 'reactstrap';
import VtxComboBox from "./component/VtxComboBox";
import FormCreator from "./FormCreator";
import { Grid } from "./model/_Components/Grid";
import Person from "./model/Persons/Person";
import { Cookies } from 'react-cookie';
import ComboBox from "./component/ComboBox";

import NotificationSystem from 'react-notification-system';
import { NOTIFICATION_SYSTEM_STYLE } from 'utils/constants';

const cookie =new Cookies();
const notificationSystem = React.createRef();
const addNotification = (_MESSAGE_, _TYPE_) => {
		const notification = notificationSystem.current;
		notification.addNotification({message: _MESSAGE_, level: _TYPE_});
	};

const __PERSON__ 		= (cookie.get("v-person-id")? cookie.get("v-person-id") : "0");
const __PERSON_ROLE__	=	cookie.get("v-person-role");


function FormProjectManagement (props){
	
	var date = new Date();
	var _date = date.toISOString().split('T')[0];
	
	
	const [records, setRecords] 							= useState([]);
	const [filterDate, setFilterDate]						= useState(_date);

	const [status, setStattus]		 						= useState(9);
	const [projectId, setProjectId] 						= useState(-1);
	
    const [taskGridConfig, setTaskGridConfig]				= useState({});
    const [taskDataSource, setTaskDataSource]				= useState({});

    const [laborGridConfig, setLaborGridConfig] 			= useState({});
    const [laborDataSource, setLaborDataSource] 			= useState({});
	
    const [materialGridConfig, setMaterialGridConfig] 		= useState({});
    const [materialDataSource, setMaterialDataSource] 		= useState({});

    const [equipmentGridConfig, setEquipmentGridConfig] 	= useState({});
    const [equipmentDataSource, setEquipmentDataSource] 	= useState({});

    const [gasMealGridConfig, setGasMealGridConfig] 		= useState({});
    const [gasMealDataSource, setGasMealDataSource] 		= useState({});

    const [gasGridConfig, setGasGridConfig] 				= useState({});
    const [gasDataSource, setGasDataSource] 				= useState({});
	
	const loadData = (_projectId, _date) => {
		const _taskdataSource = {
			type 		: 	'table',
			table		:	'rb_project_task_details',
			fields		:	['ptd_id','prj_id','task_type', 'task', 'task_description', 'sender_id', 'receiver_id', 'start_date', 'end_date'],
			getColumns	:	function(){
								var _columns	= [];
								this.fields.map(f=>_columns.push({id:f}));
								return _columns;
							},
			getRecords	:	async function(){
								var _sql	=	"SELECT ptd.* FROM " + this.table + " 	ptd  WHERE ptd.prj_id=" + _projectId + " AND ptd.task_type IN (125) AND date(ptd.start_date)>='" + _date  + "' AND date(ptd.start_date)<='" + _date + "' ORDER BY ptd.start_date;";
								var dataAdapter =new DataAdapter(_sql);
								return await dataAdapter.getRecords();
							},
			prepareUpdate:	[],
			onUpdate	:	async function(e, _primaryKey, _primaryValue, _field, _value){
								if(_primaryValue){
									var dataAdapter =new DataAdapter("UPDATE " + this.table + " SET " + _field + " = '" + _value + "' WHERE " + _primaryKey + " = '" + _primaryValue + "';");
									return await dataAdapter.getRecords();
								}else
								{
								}
							},
			prepareInsert:	[
							{field:'prj_id'	, parameters: [], prepare:(_parameter)=>{ return _projectId; }},
							{field:'task_type'	, parameters: [], prepare:(_parameter)=>{ return 125; }},
							{field:'start_date'	, parameters: [], prepare:(_parameter)=>{ return _date; }},
							{field:'sender_id'	, parameters: [], prepare:(_parameter)=>{ return __PERSON__; }},
							{field:'receiver_id', parameters: [], prepare:(_parameter)=>{ return __PERSON__; }},
							{field:'end_date'	, parameters: [], prepare:(_parameter)=>{ return _date; }},
							{field:'task_description'	, parameters: [], prepare:(_parameter)=>{ return 'PROJECT REPORT - Task'; }},
			],
			onInsert	:	async function(e, _primaryKey, _record){
								var _sql	=	"";
								var _fields	=	"";
								var _values	=	"";
								Object.keys(_record).forEach((_key)=>{
									if(_key != _primaryKey){
										_fields += 	_key + ",";
										_values +=	"'" + _record[_key] + "',";
									}
								})
								_sql = "INSERT INTO " + this.table + " (" + _fields.slice(0, -1) + ") VALUES (" + _values.slice(0, -1) + ");";
								var dataAdapter =new DataAdapter(_sql + "\r\nSELECT " + _primaryKey + " FROM " + this.table + " ORDER BY " + _primaryKey + " DESC LIMIT 1;");
								return await dataAdapter.getRecords();
							},
			onDelete	:	async function(e, _primaryKey, _primaryValue){
								if(_primaryValue){
									var dataAdapter =new DataAdapter("DELETE FROM " + this.table + " WHERE " + _primaryKey + " = '" + _primaryValue + "';");
									return await dataAdapter.getRecords();
								}else
								{
								}
							}
		};
		const _taskgridConfig	=	{
			primaryKey	:	'ptd_id',
			hasFilter	:	false,
			hasPager	:	false,
			canDelete	:	false,
			canUpdate	:	true,
			canInsert	:	true,
			aggregations:	[],
			columns		: [
				{id:'ptd_id', caption:'Id', hidden: true},
				{id:'task', caption:'Task', type:'text'},
			],
			rowClass	:	"",

		};

		setTaskGridConfig(_taskgridConfig);
		setTaskDataSource(_taskdataSource);
		
		
		const _labordataSource = {
			type 		: 	'table',
			table		:	'rb_project_task_details',
			fields		:	[],
			getColumns	:	function(){
								var _columns	= [];
								this.fields.map(f=>_columns.push({id:f}));
								return _columns;
							},
			getRecords	:	async function(){
								var _sql	=	"SELECT ptd.ptd_id,ptd.prj_id,prj.prj_department,ptd.pt_id,ptd.sender_id,ptd.receiver_id,DATE_FORMAT(ptd.start_date,'%Y-%m-%d') AS start_date, DATE_FORMAT(CONVERT_TZ(ptd.Clock_in,@@session.time_zone,'-04:00'),'%H:%i') AS Clock_in, IF(ptd.Clock_out IS NULL OR ptd.Clock_out='0000-00-00 00:00:00', '00:00', DATE_FORMAT(CONVERT_TZ(ptd.Clock_out,@@session.time_zone,'-04:00'),'%H:%i')) AS Clock_out, IF(ptd.Clock_out IS NULL OR ptd.Clock_out='0000-00-00 00:00:00', '00:00',time_format(abs(timediff(ptd.Clock_out, ptd.Clock_in)),'%H:%i')) AS `duration`, IF(ptd.Clock_out IS NULL OR ptd.Clock_out='0000-00-00 00:00:00', '0',ROUND(TIMESTAMPDIFF(MINUTE, ptd.Clock_in, ptd.Clock_out)*pr.emp_wage_amount/60,2)) AS `wage`,ptd.task_status,ptd.task_type,ptd.attachments, ptd.latitude,ptd.longitude FROM " + this.table + " 	ptd LEFT JOIN rb_person pr ON pr.person_id = ptd.sender_id LEFT JOIN rb_project_tasks pt ON pt.pt_id = ptd.pt_id LEFT JOIN rb_projects prj ON prj.prj_id = ptd.prj_id WHERE ptd.prj_id = " + _projectId + " AND ptd.task_type IN (98,122) AND date(ptd.start_date)>='" + _date  + "' AND date(ptd.start_date)<='" + _date + "' ORDER BY ptd.start_date;";
								var dataAdapter =new DataAdapter(_sql);
								return await dataAdapter.getRecords();
							},
			prepareUpdate:	[
				{field:'Clock_in'	, parameters: ['start_date','Clock_in'], prepare:(_parameter)=>{ var _UTC = (new Date(new Date(_parameter[0] + ' ' + _parameter[1]).toUTCString()).toISOString()); return (_UTC.split('T')[0] + ' ' + _UTC.split('T')[1].slice(0, 5)); }},
				{field:'Clock_out'	, parameters: ['start_date','Clock_out'], prepare:(_parameter)=>{var _UTC = (new Date(new Date(_parameter[0] + ' ' + _parameter[1]).toUTCString()).toISOString()); return (_UTC.split('T')[0] + ' ' + _UTC.split('T')[1].slice(0, 5)); }},
			],
			onUpdate	:	async function(e, _primaryKey, _primaryValue, _field, _value){
								if(_primaryValue){
									var dataAdapter =new DataAdapter("UPDATE " + this.table + " SET " + _field + " = '" + _value + "' WHERE " + _primaryKey + " = '" + _primaryValue + "';");
									return await dataAdapter.getRecords();
								}else
								{
								}
							},
			prepareInsert:	[
				{field:'Clock_in'	, parameters: ['start_date','Clock_in'], prepare:(_parameter)=>{ var _UTC = (new Date(new Date(_parameter[0] + ' ' + _parameter[1]).toUTCString()).toISOString()); return (_UTC.split('T')[0] + ' ' + _UTC.split('T')[1].slice(0, 5)); }},
				{field:'Clock_out'	, parameters: ['start_date','Clock_out'], prepare:(_parameter)=>{var _UTC = (new Date(new Date(_parameter[0] + ' ' + _parameter[1]).toUTCString()).toISOString()); return (_UTC.split('T')[0] + ' ' + _UTC.split('T')[1].slice(0, 5)); }},
			],
			prepareInsert:	[
							{field:'prj_id'	, parameters: [], prepare:(_parameter)=>{ return _projectId; }},
							{field:'start_date'	, parameters: [], prepare:(_parameter)=>{ return _date; }},
							{field:'Clock_in'	, parameters: ['Clock_in'], prepare:(_parameter)=>{ var _UTC = (new Date(new Date(_date + ' ' + _parameter[0]).toUTCString()).toISOString()); return (_UTC.split('T')[0] + ' ' + _UTC.split('T')[1].slice(0, 5)); }},
							{field:'Clock_out'	, parameters: ['Clock_out'], prepare:(_parameter)=>{ var _UTC = (new Date(new Date(_date + ' ' + _parameter[0]).toUTCString()).toISOString()); return (_UTC.split('T')[0] + ' ' + _UTC.split('T')[1].slice(0, 5)); }},
							{field:'task_status', parameters: [], prepare:(_parameter)=>{ return 9; }},
			],
			onInsert	:	async function(e, _primaryKey, _record){
								var _sql	=	"";
								var _fields	=	"";
								var _values	=	"";
								Object.keys(_record).forEach((_key)=>{
									if(_key != _primaryKey){
										_fields += 	_key + ",";
										_values +=	"'" + _record[_key] + "',";
									}
								})
								_sql = "INSERT INTO " + this.table + " (" + _fields.slice(0, -1) + ") VALUES (" + _values.slice(0, -1) + ");";
								console.log(_sql);
								var dataAdapter =new DataAdapter(_sql + "\r\nSELECT " + _primaryKey + " FROM " + this.table + " ORDER BY " + _primaryKey + " DESC LIMIT 1;");
								return await dataAdapter.getRecords();
							},
			onDelete	:	async function(e, _primaryKey, _primaryValue){
								if(_primaryValue){
									var dataAdapter =new DataAdapter("DELETE FROM " + this.table + " WHERE " + _primaryKey + " = '" + _primaryValue + "';");
									return await dataAdapter.getRecords();
								}else
								{
								}
							}
		};
		const _laborgridConfig	=	{
			primaryKey	:	'ptd_id',
			hasFilter	:	false,
			hasPager	:	false,
			canDelete	:	false,
			canUpdate	:	true,
			canInsert	:	true,
			aggregations:	[],
			columns		: [
				{id:'ptd_id', caption:'Id', hidden : true},
				{id:'sender_id', caption:'Person', type:'select', queryString:'SELECT person_id,name,lastname FROM rb_person WHERE person_type=8 AND user_expire_date>=now() ORDER BY name', valueField:'person_id', displayField:'name,lastname'},
				{id:'Clock_in', caption:'In', type:'time'},
				{id:'Clock_out', caption:'Out', type:'time'},
			],
			rowClass	:	"",

		};

		setLaborGridConfig(_laborgridConfig);
		setLaborDataSource(_labordataSource);

		const _materialDataSource = {
			type 		: 	'table',
			table		:	'rb_project_task_details',
			fields		:	['ptd_id','prj_id','task_type', 'task', 'task_description', 'sender_id', 'receiver_id', 'start_date', 'end_date','item_value','item_unit','item_qty','item_price'],
			getColumns	:	function(){
								var _columns	= [];
								this.fields.map(f=>_columns.push({id:f}));
								return _columns;
							},
			getRecords	:	async function(){
								var _sql	=	"SELECT ptd.* FROM " + this.table + " 	ptd  WHERE ptd.prj_id=" + _projectId + " AND ptd.task_type IN (120) AND date(ptd.start_date)>='" + _date  + "' AND date(ptd.start_date)<='" + _date + "' ORDER BY ptd.start_date;";
								var dataAdapter =new DataAdapter(_sql);
								return await dataAdapter.getRecords();
							},
			prepareUpdate:	[],
			onUpdate	:	async function(e, _primaryKey, _primaryValue, _field, _value){
								if(_primaryValue){
									var dataAdapter =new DataAdapter("UPDATE " + this.table + " SET " + _field + " = '" + _value + "' WHERE " + _primaryKey + " = '" + _primaryValue + "';");
									return await dataAdapter.getRecords();
								}else
								{
								}
							},
			prepareInsert:	[
							{field:'prj_id'	, parameters: [], prepare:(_parameter)=>{ return _projectId; }},
							{field:'task_type'	, parameters: [], prepare:(_parameter)=>{ return 120; }},
							{field:'start_date'	, parameters: [], prepare:(_parameter)=>{ return _date; }},
							{field:'sender_id'	, parameters: [], prepare:(_parameter)=>{ return __PERSON__; }},
							{field:'receiver_id', parameters: [], prepare:(_parameter)=>{ return __PERSON__; }},
							{field:'end_date'	, parameters: [], prepare:(_parameter)=>{ return _date; }},
							{field:'task_description'	, parameters: [], prepare:(_parameter)=>{ return 'PROJECT REPORT - Material'; }},
			],
			onInsert	:	async function(e, _primaryKey, _record){
								var _sql	=	"";
								var _fields	=	"";
								var _values	=	"";
								Object.keys(_record).forEach((_key)=>{
									if(_key != _primaryKey){
										_fields += 	_key + ",";
										_values +=	"'" + _record[_key] + "',";
									}
								})
								_sql = "INSERT INTO " + this.table + " (" + _fields.slice(0, -1) + ") VALUES (" + _values.slice(0, -1) + ");";
								var dataAdapter =new DataAdapter(_sql + "\r\nSELECT " + _primaryKey + " FROM " + this.table + " ORDER BY " + _primaryKey + " DESC LIMIT 1;");
								return await dataAdapter.getRecords();
							},
			onDelete	:	async function(e, _primaryKey, _primaryValue){
								if(_primaryValue){
									var dataAdapter =new DataAdapter("DELETE FROM " + this.table + " WHERE " + _primaryKey + " = '" + _primaryValue + "';");
									return await dataAdapter.getRecords();
								}else
								{
								}
							}
		};
		const _materialGridConfig	=	{
			primaryKey	:	'ptd_id',
			hasFilter	:	false,
			hasPager	:	false,
			canDelete	:	false,
			canUpdate	:	true,
			canInsert	:	true,
			aggregations:	[],
			columns		: [
				{id:'ptd_id', caption:'Id', hidden: true},
				{id:'task', caption:'Desc.', type:'text'},
				{id:'item_price', caption:'Amount', type:'text', prefix:'$'},
			],
			rowClass	:	"",

		};
		
		setMaterialGridConfig(_materialGridConfig);
		setMaterialDataSource(_materialDataSource);

		const _equipmentDataSource = {
			type 		: 	'table',
			table		:	'rb_project_task_details',
			fields		:	['ptd_id','prj_id','task_type', 'task', 'task_description', 'sender_id', 'receiver_id', 'start_date', 'end_date'],
			getColumns	:	function(){
								var _columns	= [];
								this.fields.map(f=>_columns.push({id:f}));
								return _columns;
							},
			getRecords	:	async function(){
								var _sql	=	"SELECT ptd.* FROM " + this.table + " 	ptd  WHERE ptd.prj_id=" + _projectId + " AND ptd.task_type IN (124) AND date(ptd.start_date)>='" + _date  + "' AND date(ptd.start_date)<='" + _date + "' ORDER BY ptd.start_date;";
								var dataAdapter =new DataAdapter(_sql);
								return await dataAdapter.getRecords();
							},
			prepareUpdate:	[],
			onUpdate	:	async function(e, _primaryKey, _primaryValue, _field, _value){
								if(_primaryValue){
									var dataAdapter =new DataAdapter("UPDATE " + this.table + " SET " + _field + " = '" + _value + "' WHERE " + _primaryKey + " = '" + _primaryValue + "';");
									return await dataAdapter.getRecords();
								}else
								{
								}
							},
			prepareInsert:	[
							{field:'prj_id'	, parameters: [], prepare:(_parameter)=>{ return _projectId; }},
							{field:'task_type'	, parameters: [], prepare:(_parameter)=>{ return 124; }},
							{field:'start_date'	, parameters: [], prepare:(_parameter)=>{ return _date; }},
							{field:'sender_id'	, parameters: [], prepare:(_parameter)=>{ return __PERSON__; }},
							{field:'receiver_id', parameters: [], prepare:(_parameter)=>{ return __PERSON__; }},
							{field:'end_date'	, parameters: [], prepare:(_parameter)=>{ return _date; }},
							{field:'task_description'	, parameters: [], prepare:(_parameter)=>{ return 'PROJECT REPORT - Equipment'; }},
			],
			onInsert	:	async function(e, _primaryKey, _record){
								var _sql	=	"";
								var _fields	=	"";
								var _values	=	"";
								Object.keys(_record).forEach((_key)=>{
									if(_key != _primaryKey){
										_fields += 	_key + ",";
										_values +=	"'" + _record[_key] + "',";
									}
								})
								_sql = "INSERT INTO " + this.table + " (" + _fields.slice(0, -1) + ") VALUES (" + _values.slice(0, -1) + ");";
								var dataAdapter =new DataAdapter(_sql + "\r\nSELECT " + _primaryKey + " FROM " + this.table + " ORDER BY " + _primaryKey + " DESC LIMIT 1;");
								return await dataAdapter.getRecords();
							},
			onDelete	:	async function(e, _primaryKey, _primaryValue){
								if(_primaryValue){
									var dataAdapter =new DataAdapter("DELETE FROM " + this.table + " WHERE " + _primaryKey + " = '" + _primaryValue + "';");
									return await dataAdapter.getRecords();
								}else
								{
								}
							}
		};
		const _equipmentGridConfig	=	{
			primaryKey	:	'ptd_id',
			hasFilter	:	false,
			hasPager	:	false,
			canDelete	:	false,
			canUpdate	:	true,
			canInsert	:	true,
			aggregations:	[],
			columns		: [
				{id:'ptd_id', caption:'Id', hidden : true},
				{id:'task', caption:'Desc.', type:'text'},
				{id:'item_price', caption:'Amount', type:'text', prefix:'$'},
			],
			rowClass	:	"",

		};
		
		setEquipmentGridConfig(_equipmentGridConfig);
		setEquipmentDataSource(_equipmentDataSource);

		const _gasMealDataSource = {
			type 		: 	'table',
			table		:	'rb_project_task_details',
			fields		:	['ptd_id','prj_id','task_type','task_description', 'start_date', 'end_date'],
			getColumns	:	function(){
								var _columns	= [];
								this.fields.map(f=>_columns.push({id:f}));
								return _columns;
							},
			getRecords	:	async function(){
								var _sql	=	"SELECT ptd.* FROM " + this.table + " 	ptd  WHERE ptd.prj_id=" + _projectId + " AND ptd.task_type IN (121,123) AND date(ptd.start_date)>='" + _date  + "' AND date(ptd.start_date)<='" + _date + "' ORDER BY ptd.start_date;";
								var dataAdapter =new DataAdapter(_sql);
								return await dataAdapter.getRecords();
							},
			prepareUpdate:	[],
			onUpdate	:	async function(e, _primaryKey, _primaryValue, _field, _value){
								if(_primaryValue){
									var dataAdapter =new DataAdapter("UPDATE " + this.table + " SET " + _field + " = '" + _value + "' WHERE " + _primaryKey + " = '" + _primaryValue + "';");
									return await dataAdapter.getRecords();
								}else
								{
								}
							},
			prepareInsert:	[
							{field:'prj_id'	, parameters: [], prepare:(_parameter)=>{ return _projectId; }},
							{field:'task_type'	, parameters: [], prepare:(_parameter)=>{ return 121; }},
							{field:'start_date'	, parameters: [], prepare:(_parameter)=>{ return _date; }},
							{field:'sender_id'	, parameters: [], prepare:(_parameter)=>{ return __PERSON__; }},
							{field:'receiver_id', parameters: [], prepare:(_parameter)=>{ return __PERSON__; }},
							{field:'end_date'	, parameters: [], prepare:(_parameter)=>{ return _date; }},
							{field:'task_description'	, parameters: [], prepare:(_parameter)=>{ return 'PROJECT REPORT - Gas'; }},
			],
			onInsert	:	async function(e, _primaryKey, _record){
								var _sql	=	"";
								var _fields	=	"";
								var _values	=	"";
								Object.keys(_record).forEach((_key)=>{
									if(_key != _primaryKey){
										_fields += 	_key + ",";
										_values +=	"'" + _record[_key] + "',";
									}
								})
								_sql = "INSERT INTO " + this.table + " (" + _fields.slice(0, -1) + ") VALUES (" + _values.slice(0, -1) + ");";
								var dataAdapter =new DataAdapter(_sql + "\r\nSELECT " + _primaryKey + " FROM " + this.table + " ORDER BY " + _primaryKey + " DESC LIMIT 1;");
								return await dataAdapter.getRecords();
							},
			onDelete	:	async function(e, _primaryKey, _primaryValue){
								if(_primaryValue){
									var dataAdapter =new DataAdapter("DELETE FROM " + this.table + " WHERE " + _primaryKey + " = '" + _primaryValue + "';");
									return await dataAdapter.getRecords();
								}else
								{
								}
							}
		};
		const _gasMealGridConfig	=	{
			primaryKey	:	'ptd_id',
			hasFilter	:	false,
			hasPager	:	false,
			canDelete	:	false,
			canUpdate	:	true,
			canInsert	:	true,
			aggregations:	[],
			columns		: [
				{id:'ptd_id', caption:'Id', hidden : true},
				{id:'task', caption:'Desc.', type:'text'},
				{id:'item_price', caption:'Amount', type:'text', prefix:'$'},
			],
			rowClass	:	"",

		};
		
		setGasMealGridConfig(_gasMealGridConfig);
		setGasMealDataSource(_gasMealDataSource);


	}

	useEffect(() => {
		if(projectId!=-1)			
			loadData(projectId, filterDate);
    }, [filterDate, projectId]);

	return(
		<>
			<Page className="DashboardPage" title="Project Report" >
				<Row>			
					<Col lg={12} md={12} sm={12} xs={12} >
						<ComboBox title="Projects" onChange={async (e)=>{
							setProjectId(e.target.value);
						}} table="rb_projects" filterField="prj_status" filterValue={status + "' AND prj_department IN (SELECT rb_type_no FROM rb_types WHERE rb_type='prj_department' AND rb_type_type_id=1 AND (rb_type_type_no>0 OR rb_type_type_no=-1)) AND 'x'='x"} valueField="prj_id" displayField="prj_id,prj_address"  orderby="prj_id" defaultValue={projectId} />
					</Col>
				</Row>
				<Row>
					<Col lg={12} md={12} sm={12} xs={12} >
						<Input type={'date'} onChange={(e)=>{e.persist(); setFilterDate(e.target.value)}} value={filterDate} />
					</Col>
				</Row>
				<Row>					
					<Col lg={12} md={12} sm={12} xs={12} >
						<h6>Tasks</h6>
						<Grid config={taskGridConfig} dataSource={taskDataSource} callback={(callBack)=>{if(callBack.response)addNotification(callBack.response[0].message,"info");}} />
					</Col>
				</Row>
				<Row>					
					<Col lg={12} md={12} sm={12} xs={12} >
						<h6>LABOR</h6>
						<Grid config={laborGridConfig} dataSource={laborDataSource} callback={(callBack)=>{if(callBack.response)addNotification(callBack.response[0].message,"info");}} />
					</Col>
				</Row>
				<Row>					
					<Col lg={12} md={12} sm={12} xs={12} >
						<h6>MATERIAL</h6>
						<Grid config={materialGridConfig} dataSource={materialDataSource} callback={(callBack)=>{if(callBack.response)addNotification(callBack.response[0].message,"info");}} />
					</Col>
				</Row>
				<Row>					
					<Col lg={12} md={12} sm={12} xs={12} >
						<h6>EQUIPMENT</h6>
						<Grid config={equipmentGridConfig} dataSource={equipmentDataSource} callback={(callBack)=>{if(callBack.response)addNotification(callBack.response[0].message,"info");}} />
					</Col>
				</Row>
				<Row>					
					<Col lg={12} md={12} sm={12} xs={12} >
						<h6>GAS/MEALS</h6>
						<Grid config={gasMealGridConfig} dataSource={gasMealDataSource} callback={(callBack)=>{if(callBack.response)addNotification(callBack.response[0].message,"info");}} />
					</Col>
				</Row>
			</Page>
			<NotificationSystem ref={notificationSystem} style={NOTIFICATION_SYSTEM_STYLE}/>			
		</>
	);
}
export default FormProjectManagement;
