import { STATE_LOGIN, STATE_SIGNUP } from 'vortex/component/AuthForm';
import React from 'react';
import '../styles/reduction.scss';
import '../App.css';

import { EmptyLayout, LayoutRoute, MainLayout } from 'components/Layout';
import { BrowserRouter, HashRouter, Redirect, Route, Switch } from 'react-router-dom';
import PageSpinner from 'components/PageSpinner';
import componentQueries from 'react-component-queries';
import AuthPage from 'vortex/AuthPage';
import ViewProjectTaskNote from 'vortex/ViewProjectTaskNote';
import { Cookies } from 'react-cookie';
import Base64 from 'Base64';
import DataAdapter from './adapter/DataAdapter';

import ViewBoards from 'vortex/ViewBoards';
import ViewTaskOfPerson from 'vortex/ViewTaskOfPerson';
import ViewProjectOfPerson from 'vortex/ViewProjectOfPerson';
import ViewProjectInProgress from 'vortex/ViewProjectInProgress';
import TileMenu from 'vortex/TileMenu';
import '../LoadingScreen.css';

const DashBoard = React.lazy(() => import('vortex/dashboard/DashBoard'));
const DashBoardFinancial = React.lazy(() => import('vortex/dashboard/DashBoardFinancialByProject'));
const cookie =new Cookies();

var link = require('vortex/config/link.json');


const LoadingScreen = () => {
    return (
      <div className="loading-screen">
        <div className="loading-spinner"></div>
      </div>
    );
};
const getBasename = () => {
    return `/${process.env.PUBLIC_URL.split('/').pop()}`;
  };
const getRestAPI = () => {
    return `${process.env.REACT_APP_REST_URL}`;
  };
  
  
class App extends React.Component{
    constructor(props){
        super(props);
        this.state = {
          links    : link,
        };
    
        let requestOptions = {
          method: 'GET',
          headers: {
            'Authorization':'Basic ' + Base64.btoa('sizotech:IsKfs$m$SZV#4Eprtj7zSPbO')
          }
        };
    

        fetch( getRestAPI() + '/wp-json/reno/v1/rb_link', requestOptions)
        .then(response => {
          return Promise.all([response.json(),response])
        })
        .then(([responseData,response]) => {
          this.setState({links : responseData});
        })
        .catch(error => {
          console.error('Error:', error);
        })		    
          
    }
    render(){
      var forms = this.state.forms;
      
      if(cookie.get('v-email') != null){
          return(
          <>
            <HashRouter basename={getBasename()} >
                <Switch>
                    <MainLayout breakpoint={this.props.breakpoint} id="main" >
                        <React.Suspense fallback={<PageSpinner />}>
							{cookie.get('v-person-role')==13 ?
								<>
									<Route exact path="/login"   render={props => (<TileMenu  /> )} />
									<Route exact path="/"   render={props => (<TileMenu  /> )} />
								</>
							:
								<>
                                    <Route exact path="/DepartmentActiveProjects/:status/:department"    component={React.lazy(() => import('./ViewProjectOfPerson'))} />
									<Route exact path="/login"   render={props => (<ViewTaskOfPerson status="7,8,9,10,11" /> )} />
									<Route exact path="/"   render={props => (<ViewTaskOfPerson status="7,8,9,10,11" /> )} />
								</>
							}

                            {
                              this.state.links.map(l=>{
                                  return(
                                    <>
                                      {l.module?
                                      <Route exact path={l.path }    component={React.lazy(() => import('./' + l.module))} />
                                      :''}
                                    </>
                                  )
                              })
                            }
                        </React.Suspense>
                    </MainLayout>
                    <Redirect to={'/'} />
                </Switch>
            </HashRouter>
          </>
        )
      }else{
        return(
          <HashRouter basename={getBasename()} >
              <Switch>
                  <LayoutRoute exact path="/login" layout={EmptyLayout} component={props => (<AuthPage {...props} authState={STATE_LOGIN} />)} />
                  <LayoutRoute exact path="/signup" layout={EmptyLayout} component={props => (<AuthPage {...props} authState={STATE_SIGNUP} />)} />
                  <LayoutRoute path="/" layout={EmptyLayout} component={props => (<AuthPage {...props} authState={STATE_LOGIN} />)} />  
              </Switch>
          </HashRouter>
        )
      }
    }
    getQueryString=(param)=>{
      var _result = "";
      var indexOfQuestionMark = window.location.href.indexOf('?');
      if(indexOfQuestionMark == -1)
        return _result;
      var queryString = window.location.href.substring(indexOfQuestionMark);
      var indexOfParam = queryString.indexOf(param + '=');
      var indexOfParamEnd = queryString.indexOf('&', indexOfParam);
      if(indexOfParamEnd==-1)
        _result = queryString.substring(indexOfParam + param.length +1);
      else
        _result = queryString.substring(indexOfParam + param.length +1, indexOfParamEnd);
    
      return _result;
    }
    
}

const query = ({ width }) => {
    if (width < 575) {
      return { breakpoint: 'xs' };
    }
  
    if (576 < width && width < 767) {
      return { breakpoint: 'sm' };
    }
  
    if (768 < width && width < 991) {
      return { breakpoint: 'md' };
    }
  
    if (992 < width && width < 1199) {
      return { breakpoint: 'lg' };
    }
  
    if (width > 1200) {
      return { breakpoint: 'xl' };
    }
  
    return { breakpoint: 'xs' };
};

export default componentQueries(query)(App);
  