import AuthForm, { STATE_LOGIN } from '../vortex/component/AuthForm';
import React from 'react';
import { Card, Col, Row } from 'reactstrap';
import QuickFormTask from './QuickFormTask';

class AuthPage extends React.Component {
  handleAuthState = authState => {
    if (authState === STATE_LOGIN) {
      this.props.history.push('/login');
    } else {
      this.props.history.push('/signup');
    }
  };

  handleLogoClick = () => {
    this.props.history.push('/');
  };

  render() {
    return (
      <Row
        style={{
          height: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
		  backgroundColor:'#191919'
        }}>
        <Col md={6} lg={4} className="p-4" >
          <Card body>
            <AuthForm
			  showLogo={true}
              authState={this.props.authState}
              onChangeAuthState={this.handleAuthState}
              onLogoClick={this.handleLogoClick}
              backlink={this.props.location }
            />
          </Card>
        </Col>
        <Col md={6} lg={4} className="p-4" >
			<h2 className="text-secondary" >AugmentSite™</h2>
			<h1 className="text-light">Elevating Construction to Excellence</h1>
			<p  className="text-light">
			Welcome to AugmentSite
				Your trusted partner in construction excellence. By combining the power of advanced technologies with streamlined project management, AugmentSite empowers you to build smarter, faster, and with unparalleled precision. From real-time site insights to efficient task management, we're here to elevate every phase of your construction journey. Log in to experience the future of construction management, where innovation meets excellence.
			</p>
			<img src={require('../assets/img/logo/Analysion-Website-Logo.png')} height="50" alt="AugmentSite™ by Analysion Inc." title="AugmentSite™ by Analysion Inc."  />
        </Col>
      </Row>
    );
  }
}

export default AuthPage;
