import React, {useEffect} from 'react';
import ReactDOM from 'react-dom';

import App								from 	'./vortex/App'
import FormProjectManagement			from 	'./vortex/FormProjectManagement'
import Task								from 	'./vortex/QuickFormTask'

//ReactDOM.render(<App  />, document.getElementById('root'));
//ReactDOM.render(<FormProjectManagement  />, document.getElementById('root'));
ReactDOM.render(<Task  />, document.getElementById('root'));
