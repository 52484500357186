import React from "react";
import Base64 from 'Base64';
import { Button, Card, CardBody, CardHeader, Col, Form, FormGroup, Input, Label } from "reactstrap";
import NotificationSystem from 'react-notification-system';
import { NOTIFICATION_SYSTEM_STYLE } from 'utils/constants';
import VtxComboBox from "./component/VtxComboBox";

const _DATA_FORMS_ = require('vortex/config/form.json');
const json = require('vortex/config/module.json');

var __NAME__, __VALUE__;

class FormCreator extends React.Component {
    notificationSystem = React.createRef();

    constructor(props){
        super(props);
		if(props.match){
			__NAME__ = this.props.match.params.name;
            __VALUE__ = this.props.match.params.values;
    	}else{
			__NAME__ = this.props.name;
            __VALUE__ = this.props.values;
	    }
        
        var title = _DATA_FORMS_.find(i=>{return i.name == __NAME__}).title;
        var fields = _DATA_FORMS_.find(i=>{return i.name == __NAME__}).fields;
        var defaults = {};
        fields.map(f=>{
            defaults[f.name] = "";
        });
        this.state ={
            id : 0,
            title : title,
            name : __NAME__,
            fields : fields,
            values : defaults//(__VALUE__? __VALUE__ : defaults),
        }

    }

    handleEvents = (event)=>{
        console.log("formcreator->");
        this.props.handleEvents(event);
    }

    handlePrimary=(key)=>{
        if(this.props.handlePrimary)
            this.props.handlePrimary(key);
    }

    onFieldChangeHandler=(event)=>{
		event.persist();
        var _values = this.state.values;
        _values[event.target.id] = event.target.value;
        if(event.target.type=='checkbox')
            _values[event.target.id] = (event.target.checked? 1: 0);
        this.setState({
            values : _values
        });
        console.log(event);
		//console.log(event.target.id + "=" + event.target.value);

	}

	onSubmitHandler=(event)=>{
		// Build formData object.
		let formData = new FormData();

        var _values = this.state.values;
        Object.keys(_values).forEach(key=>{
            formData.append(key, _values[key]);
        })

        
		fetch("https://renobuilding.ca/wp-json/reno/v1/" + this.state.name,
			{
				method: "POST",
				headers: {
					'Authorization':'Basic ' + Base64.btoa('sizotech:IsKfs$m$SZV#4Eprtj7zSPbO')
				},
				body: formData
			})
			.then(response => {
                return Promise.all([response.json(),response])
            })
            .then(([responseData,response]) => {
                this.setState({id : responseData});
                this.handlePrimary(responseData);
                this.addNotification('Data has been registered. (' + this.state.name + ')');

            })
            .catch(error => {
                console.error('Error:', error);
            })		
        
	}

    componentWillReceiveProps(nextProps){
        var defaults = {};
        this.state.fields.map(f=>{
            defaults[f.name] = "";
        });
        this.setState({
            values : (nextProps.values? nextProps.values : defaults)
        });
    }

    comboBoxOnChange = (key)=>{
        var _values = this.state.values;
        _values[key.id] = key.selectedItems;
        console.log(_values);
    }
    addNotification (_MESSAGE_) {
		const notification = this.notificationSystem.current;
		notification.addNotification({
		  message: _MESSAGE_,
		  level: 'success'
		});
	  };	

    render(){
        return (
            <div>
                <Card>
                    <CardHeader>{this.state.title}</CardHeader>
                    <CardBody>
                        <Form>
                            {this.state.fields?this.state.fields.map(
                                f=>{
                                    return (
                                        <FormGroup>
                                            <Label for={f.name} >{f.label}</Label>
                                            <Col>
                                                {f.refer?
                                                    f.refer.type=='table'?
                                                    <VtxComboBox name={f.name} id={f.name} comboBoxOnChange={this.comboBoxOnChange}  table={f.refer.table} filterField={f.refer.filterField} filterValue={f.refer.filterValue} valueField={f.refer.valueField} displayField={f.refer.displayField} multiSelect={f.refer.multiSelect} /> 
                                                    :
                                                    f.refer.type=='json'?
                                                        <>
                                                            <Input type="select" name={f.name} id={f.name} onChange={this.onFieldChangeHandler} value={f.primary? this.state.id : (this.state.values!=null ? this.state.values[f.name] : '')} >
                                                                <option value="" >-</option>
                                                            {json.map(i=>{return(
                                                                <option value={i.mo_name} >{i.mo_name}</option>
                                                                )})}
                                                            </Input>
                                                        </>
                                                    :
                                                    ''
                                                :
                                                    <Input type={f.type} name={f.name} id={f.name} onChange={this.onFieldChangeHandler} value={f.primary? this.state.id : (this.state.values!=null ? this.state.values[f.name] : '')} >
                                                        {f.option?
                                                            f.option.map(o=>{
                                                                return (
                                                                    <option value={o.value} >{o.text}</option>
                                                                );
                                                            })
                                                        :""}
                                                    </Input>
                                        }
                                                {f.form != null? 
                                                    <>
                                                       <FormCreator name={f.form} values={this.state.values[f.form]} handlePrimary={this.handlePrimary} />
                                                    </>
                                                :
                                                ""
                                                }
                                            </Col>
                                        </FormGroup>
                                    )
                                }
                            ) : ""}
                                        <FormGroup>
                                            <Col sm={{ size: 10, offset: 2 }}>
                                                <Button onClick={this.onSubmitHandler} >Register</Button>
                                            </Col>
                                        </FormGroup>
                        </Form>
                    </CardBody>
                </Card>
                <NotificationSystem ref={this.notificationSystem} style={NOTIFICATION_SYSTEM_STYLE}/>
            </div>
        )
    }
}

export default FormCreator;