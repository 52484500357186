import React from 'react';
import Page from 'components/Page';
import {
	Button,
	ButtonGroup,
	Card,
	CardBody,
	CardHeader,
	Col,
	Form,
	FormFeedback,
	FormGroup,
	FormText,
	Input,
	Label,
	Row,
  } from 'reactstrap';
  import Base64 from 'Base64';
  import { Cookies } from 'react-cookie';
  import ComboBox from './component/ComboBox.js';
  import NotificationSystem from 'react-notification-system';
  import { NOTIFICATION_SYSTEM_STYLE } from 'utils/constants';

  const cookie =new Cookies();
  
  
class QuickFormTask extends React.Component {
	notificationSystem = React.createRef();
	constructor(props){
		super(props);
		this.state = {
			prj_id				:	1230,
			project				:	0,
			board				:	0,
			board_type			:	0,
			sender_id			:	0,
			receiver_id			:	0,
			task 				:	'',
			task_status			:	8,
			task_type			:	92,
			expected_end_date	:	(new Date()).getFullYear() + '/12/28',
		};

	}
	addNotification(_MESSAGE_) {
		const notification = this.notificationSystem.current;
		notification.addNotification({
			message: _MESSAGE_,
			level: 'success'
		});
	};

	runQuery = (query, message) => {
		let formData = new FormData();
		formData.append("query", query);
		let requestOptions = { method: 'POST', headers: { 'Authorization': 'Basic ' + Base64.btoa('sizotech:IsKfs$m$SZV#4Eprtj7zSPbO') }, body: formData };
		fetch('https://renobuilding.ca/wp-json/reno/v1/query/', requestOptions)
			.then(response => {
				return Promise.all([response.json(), response])
			})
			.then(([responseData, response]) => {
				this.addNotification(message, "success");

			})
			.catch(error => {
				console.error('Error:', error);
			})
	}
	
	onSubmitHandler = (e) =>{
		this.runQuery("INSERT INTO rb_project_tasks (prj_id, sender_id, receiver_id, task, task_status, task_type, start_date, expected_end_date, create_date) values (" + this.state.prj_id + ", " + this.state.sender_id + "," + this.state.receiver_id + ",'" + this.state.task + "'," + this.state.task_status + "," + this.state.task_type + ",now(),'" + this.state.expected_end_date + "',now())","The task has been refered");
		//console.log(this.state);
	}

	render(){
		return (
			<Row>
				<Col xl={12} lg={12} md={12}>
					<Card>
						<CardHeader>8CO Task</CardHeader>
						<CardBody>
						<Form>
						<FormGroup row >
							<Col sm={12} >
							  <ButtonGroup className="mr-3 mb-3">
								<Button color={this.state.board_type==0?"primary":"light"} onClick={(e)=>{this.setState({board_type:0});}} >Internal</Button>
								<Button color={this.state.board_type==1?"primary":"light"} onClick={(e)=>{this.setState({board_type:1});}} >External</Button>
							  </ButtonGroup>
							</Col>
						</FormGroup>
						<FormGroup row >
							<Label for="project" sm={12}>Board</Label>
							<Col sm={12} >
								{this.state.board_type==0?
								<ComboBox onChange={(e)=>{this.setState({project : e.target.value.split(',')[0], board: e.target.value.split(',')[1]}) }} table="rb_projects" valueField="prj_id,prj_code" displayField="prj_code,prj_title" filterField="prj_status" filterValue={"23' AND prj_code IN (SELECT distinct rb_type_caption FROM rb_types WHERE rb_type='task_type' AND rb_type_caption <> '') AND 'x'='x"} defaultValue={this.state.project+','+this.state.board} orderby="prj_code"  />
								:
								<ComboBox onChange={(e)=>{this.setState({project : e.target.value.split(',')[0], board: e.target.value.split(',')[1]}) }} table="rb_projects" valueField="prj_id,prj_code" displayField="prj_code,prj_title" filterField="prj_status" filterValue={"9' AND prj_department IN (SELECT rb_type_no FROM rb_types WHERE rb_type='prj_department' AND rb_type_type_id=1 AND (rb_type_type_no>0 OR rb_type_type_no=-1)) AND 'x'='x"} defaultValue={this.state.project+','+this.state.board} orderby="prj_code"  />
								}
							</Col>
						</FormGroup> 
						
						<FormGroup row >
							<Label for="task_type" sm={12}>Task Type</Label>
							<Col sm={12} >
								{this.state.board_type==0?
									<ComboBox onChange={(e)=>{this.setState({task_type : e.target.value}) }} table="rb_types" valueField="rb_type_no" displayField="rb_type_desc" filterField="rb_type" filterValue={"task_type' AND rb_type_caption='" + this.state.board + "' AND 'x'='x"} orderby="rb_type_desc" defaultValue={this.state.task_type} />
								:
									<ComboBox onChange={(e)=>{this.setState({task_type : e.target.value}) }} table="rb_types" valueField="rb_type_no" displayField="rb_type_desc" filterField="rb_type" filterValue={"task_type' AND rb_type_caption='' AND 'x'='x"} orderby="rb_type_desc" defaultValue={this.state.task_type} />
								}
							</Col>
						</FormGroup>
						<FormGroup row >
							<Label for="receiver_id" sm={12}>Receiver</Label>
							<Col sm={12} >
								<ComboBox id="receiver_id" onChange={(e)=>{this.setState({receiver_id	:	e.target.value})}} table="rb_person" valueField="person_id" displayField="name,lastname" filterField="person_type" filterValue={"8' AND (person_id IN (SELECT bm_person FROM rb_board_members WHERE bm_board=" + this.state.board + ")) AND user_expire_date>now() AND 'x'='x"} defaultValue={cookie.get('v-person-id')} orderby="name" />
							</Col>
						</FormGroup>
						<FormGroup row >
							<Label for="task" sm={12}>TASK</Label>
							<Col sm={12} >
								<Input
									type="text"
									name="task"
									id="task"
									placeholder=""
									onChange={(e)=>{this.setState({task	:	e.target.value})}}
									defaultValue={this.state.task}
								/>
							</Col>
						</FormGroup>
							<FormGroup row>
								<Col>
									<Button onClick={this.onSubmitHandler} >Assign</Button>
								</Col>
							</FormGroup>
						</Form>
						</CardBody>
					</Card>
				</Col>
				<NotificationSystem ref={this.notificationSystem} style={NOTIFICATION_SYSTEM_STYLE} />
			</Row>
				);
	}
}

export default QuickFormTask;